import type { FunctionComponent } from "react";

import type { BillingCycle } from "scmp-app/components/posties/subscription-widget/types";

import { Container, CycleLabel, LabelContainer, Switch } from "./styles";

type Props = {
  billingCycle: BillingCycle;
  className?: string;
  handleChange: (billingCycle: BillingCycle) => void;
};

export const BillingCycleToggle: FunctionComponent<Props> = ({
  billingCycle,
  className,
  handleChange,
}) => {
  const renderLabel = (variant: BillingCycle) => {
    const computeViewModel = () => {
      const isSelected = billingCycle === variant;
      switch (variant) {
        case "1m":
          return { cycle: "Monthly", isSelected };
        case "1y":
          return { cycle: "Yearly", isSelected };
      }
    };
    const viewModel = computeViewModel();

    return (
      <LabelContainer $isSelected={viewModel.isSelected} onClick={() => handleChange(variant)}>
        <CycleLabel>{viewModel.cycle}</CycleLabel>
      </LabelContainer>
    );
  };

  return (
    <Container className={className}>
      {renderLabel("1m")}
      <Switch
        checked={billingCycle === "1y"}
        disableFocusRipple
        disableRipple
        onChange={event => handleChange?.(event.target.checked ? "1y" : "1m")}
      />
      {renderLabel("1y")}
    </Container>
  );
};
