import styled from "@emotion/styled";
import { fontAngkor, theme } from "@product/scmp-sdk";

import { BillingCycleToggle } from "./billing-cycle-toggle";
import { Footer as Footer_ } from "./footer";
import { PlanOption } from "./plan-option";

export const Title = styled.div`
  margin-block-end: 20px;

  color: #0f75bf;
  font-family: ${fontAngkor};
  letter-spacing: -0.04em;
`;

export const Toggle = styled(BillingCycleToggle)`
  display: flex;
  align-items: flex-start;

  margin-block-end: 12px;

  ${theme.breakpoints.up("desktop")} {
    margin-block-end: 60px;
  }
`;

export const EPaperPlan = styled(PlanOption)`
  margin-block-end: 20px;
`;
export const PrintPlan = styled(PlanOption)`
  margin-block-end: 20px;
`;

export const Footer = styled(Footer_)``;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up("desktop")} {
    flex-direction: row;
    gap: 24px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-block: 40px;
  padding-inline: 20px;

  ${Title} {
    font-size: 36px;
    text-align: center;
  }

  ${theme.breakpoints.up("desktop")} {
    padding: 48px;

    ${Title} {
      font-size: 56px;
    }
  }
`;
