import styled from "@emotion/styled";
import Switch_, { switchClasses } from "@mui/material/Switch";
import { fontFredoka } from "@product/scmp-sdk";

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

type LabelProps = {
  $isSelected: boolean;
};
export const LabelContainer = styled.div<LabelProps>`
  color: #333333;
  font-family: ${fontFredoka};
  text-align: center;

  cursor: pointer;

  opacity: ${props => (props.$isSelected ? 1 : 0.5)};
`;

export const CycleLabel = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
`;

export const Switch = styled(Switch_)`
  &.${switchClasses.root} {
    inline-size: 44px;
    block-size: 24px;
    margin-inline: 20px;
    padding: 0;
  }

  .${switchClasses.switchBase} {
    margin: 3px;
    padding: 0;

    transition: all 0.4s ease 0s;

    &.${switchClasses.checked} {
      & + .${switchClasses.track} {
        background: #389810;

        opacity: 1;
      }
    }
  }

  .${switchClasses.track} {
    border: 2px solid #000000;
    border-radius: 12px;

    background: #389810;

    opacity: 1;
  }

  .${switchClasses.thumb} {
    inline-size: 18px;
    block-size: 18px;
    box-sizing: border-box;

    border: 2px solid #000000;

    background-color: #ffffff;
  }
`;
