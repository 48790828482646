import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontFredoka, theme, transientOptions } from "@product/scmp-sdk";

import { OffsetContainer } from "scmp-app/components/posties/offset-container";
import { setOffsetContainerCssVariables } from "scmp-app/components/posties/offset-container/styles";

export const Mascot = styled.img``;

export const Icon = styled(OffsetContainer)`
  grid-area: Icon;

  border: 0;

  ${theme.breakpoints.down("tablet")} {
    ${setOffsetContainerCssVariables({ $borderWidth: "0", $offset: 0 })}
  }

  ${Mascot} {
    inline-size: 100%;
    block-size: 100%;
  }
`;

type BalloonContainerProps = {
  $isVisible: boolean;
  $position: "top-left" | "top-right";
};

export const BalloonContainer = styled.div<BalloonContainerProps>`
  display: none;

  ${theme.breakpoints.up("desktop")} {
    position: absolute;
    z-index: 1;

    display: flex;

    ${props =>
      props.$position === "top-left" &&
      css`
        inset-block-start: -20px;
        inset-inline-start: -45px;
      `}

    ${props =>
      props.$position === "top-right" &&
      css`
        inset-block-start: -28px;
        inset-inline-end: -50px;
      `}
  }
`;

export const Title = styled.div`
  grid-area: Title;

  color: #389810;
  font-family: ${fontFredoka};
  white-space: nowrap;
  text-align: center;
`;

export const PriceContainer = styled.div`
  grid-area: Price;

  display: flex;
  align-items: flex-end;

  inline-size: fit-content;
`;

export const Currency = styled.div`
  order: 1;

  color: #333333;
  font-weight: 600;
  font-family: ${fontFredoka};
`;

const Price = styled.div`
  position: relative;

  color: #333333;
  font-weight: 600;
  font-family: ${fontFredoka};
`;

export const IntroPrice = styled(Price)`
  order: 3;

  margin-inline-start: 8px;
`;

export const RackPrice = styled(Price)`
  order: 2;

  margin-inline-start: 4px;
  ${IntroPrice} + & {
    inset-block-start: 0;

    block-size: unset;

    color: #eb4d42;
    font-weight: 400;

    &::after {
      position: absolute;
      inset-block-start: 50%;
      inset-inline: -4px;
      content: "";

      inline-size: calc(100% + 8px);
      block-size: 0;

      border: 1px solid #eb4d42;

      background: #eb4d42;

      transform: rotate(-30deg);
    }
  }
`;

export const BillingCycleLabel = styled.div`
  order: 4;

  margin-inline-start: 4px;

  color: #333333;
  font-size: 18px;
  font-family: ${fontFredoka};
  line-height: 18px;
`;

export const PriceDescription = styled.div`
  grid-area: PriceDescription;

  color: #666666;
  font-size: 14px;
  font-family: ${fontFredoka};
  line-height: 20px;
  white-space: nowrap;

  opacity: 0.7;
`;

export const Description = styled.div`
  grid-area: Description;

  min-inline-size: 268px;

  color: #333333;
  font-weight: 400;
  font-size: 15px;
  font-family: ${fontFredoka};
  line-height: 18px;
`;

export const Content = styled.div<Props>`
  display: grid;
  grid:
    "Icon . Title" min-content
    "Icon . ." 10px
    "Icon . Price" min-content
    "Icon . ." 6px
    "Icon . PriceDescription" min-content
    ". . PriceDescription" min-content
    ". . ." 12px
    "Description Description Description" min-content
    / min-content 14px min-content;
  align-items: center;

  min-inline-size: 335px;
  padding-block: 18px;
  padding-inline: 16px;

  cursor: pointer;

  ${Title} {
    justify-self: flex-start;

    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  ${Icon} {
    z-index: 1;

    inline-size: 80px;
    block-size: 80px;

    & > * {
      inline-size: 100%;
      block-size: 100%;
    }
  }

  ${Currency} {
    font-size: 18px;
    line-height: 18px;
  }

  ${IntroPrice}, ${RackPrice} {
    font-size: 40px;
    line-height: 32px;
  }

  ${IntroPrice} + ${RackPrice} {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }

  ${BillingCycleLabel} {
    font-size: 14px;
    line-height: 16px;
  }

  ${PriceDescription} {
    font-size: 12px;
    line-height: 17px;
  }

  ${Description} {
    font-size: 12px;
    line-height: 14px;
  }

  ${theme.breakpoints.up("desktop")} {
    grid:
      "Icon" min-content
      "." 10px
      "Title" min-content
      "." 20px
      "Price" min-content
      "." 10px
      "PriceDescription" min-content
      "." 20px
      "Description" min-content
      / min-content;
    place-items: center center;

    padding-block: 0 32px;
    padding-inline: 24px;

    text-align: center;

    ${Title} {
      justify-self: center;

      font-size: 32px;
      line-height: 32px;
    }

    ${Icon} {
      z-index: 1;

      inline-size: 120px;
      block-size: 120px;
      margin-block-start: -60px;

      aspect-ratio: unset;
    }

    ${Currency} {
      font-size: 22px;
      line-height: 22px;
    }

    ${IntroPrice}, ${RackPrice} {
      font-size: 56px;
      line-height: 40px;
    }

    ${IntroPrice} + ${RackPrice} {
      font-size: 22px;
      line-height: 22px;
    }

    ${BillingCycleLabel} {
      font-size: 14px;
      line-height: 16px;
    }

    ${PriceDescription} {
      font-size: 14px;
      line-height: 20px;
    }

    ${Description} {
      font-size: 15px;
      line-height: 18px;
    }
  }
`;

type Props = {
  $isDisabled?: boolean;
};

export const Container = styled(OffsetContainer, { ...transientOptions })<Props>`
  ${props =>
    props.$isDisabled
      ? css`
          && {
            pointer-events: none;

            ${setOffsetContainerCssVariables({
              $backgroundColor: "#ffffff",
              $borderColor: "#bbbbbb",
            })}

            ${Icon} {
              ${setOffsetContainerCssVariables({
                $backgroundColor: "#f7f7f7",
                $borderColor: "#a1a1a1",
              })}
            }

            ${BalloonContainer} {
              display: none;
            }

            ${Mascot} {
              filter: grayscale(1) opacity(0.5);
            }

            * {
              color: #bbbbbb;
            }

            ${Description} {
              color: #d7d7d7;
            }
          }
        `
      : null}
`;
