import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontFredoka, theme, transientOptions } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import { OffsetContainer } from "scmp-app/components/posties/offset-container";
import { setOffsetContainerCssVariables } from "scmp-app/components/posties/offset-container/styles";

import IconArrow_ from "./arrow.svg";

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const Benefit = styled(BaseLink)`
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;

  color: #0f75bf;
  font-size: 15px;
  font-family: ${fontFredoka};
  line-height: 18px;
`;

export const IconArrow = styled(IconArrow_)`
  inline-size: 18px;
  block-size: 18px;
`;

type SubscribeProps = {
  $isDisabled?: boolean;
};
export const Subscribe = styled(OffsetContainer, { ...transientOptions })<SubscribeProps>`
  padding-block: 12px;
  padding-inline: 24px;

  color: #000000;
  font-weight: 600;
  font-size: 16px;
  font-family: ${fontFredoka};
  line-height: 20px;
  text-transform: uppercase;

  ${setOffsetContainerCssVariables({
    $backgroundColor: "#fecc17",
  })}

  ${Benefit} + & {
    margin-block-start: 40px;
  }

  ${props =>
    props.$isDisabled
      ? css`
          & {
            * {
              color: #a1a1a1;

              pointer-events: none;
            }

            ${setOffsetContainerCssVariables({
              $backgroundColor: "#ffffff",
              $borderColor: "#a1a1a1",
            })}
          }
        `
      : null}
`;

const Description = styled.div`
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontFredoka};
  font-style: normal;
  line-height: 24px;
  text-align: center;

  ${Benefit} + & {
    margin-block-start: 20px;
  }

  ${Subscribe} + & {
    margin-block-start: 14px;
  }

  ${theme.breakpoints.up("tablet")} {
    ${Benefit} + & {
      margin-block-start: 16px;
    }
  }
`;

const linkStyles = css`
  color: #0f75bf;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: underline;

  cursor: pointer;
`;
export const Link = styled(BaseLink)`
  ${linkStyles}
`;

export const LogIn = styled(Description)``;
export const LogInLink = styled.a`
  ${linkStyles}
`;

export const Redeem = styled(Description)``;
