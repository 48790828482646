import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";

import { BaseLink } from "shared/components/common/base-link";

import type { Plan } from "scmp-app/components/posties/subscription-widget/types";
import { rosettaAtom } from "scmp-app/lib/rosetta";

import IconBlueBalloon from "./blue-balloon.svg";
import IconMascotPeter from "./mascot-peter.png";
import IconMascotPippa from "./mascot-pippa.png";
import IconRedBalloon from "./red-balloon.svg";
import {
  BalloonContainer,
  BillingCycleLabel,
  Container,
  Content,
  Currency,
  Description,
  Icon,
  IntroPrice,
  Mascot,
  PriceContainer,
  PriceDescription,
  RackPrice,
  Title,
} from "./styles";

export type Props = {
  className?: string;
  handleSelected?: () => void;
  isSelected?: boolean;
  plan: Plan;
  withBalloon: boolean;
  withLink?: {
    url: string;
  };
};

export const PlanOption: FunctionComponent<Props> = ({
  className,
  handleSelected,
  isSelected,
  plan,
  withBalloon,
  withLink,
}) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isOverallChurned = asyncRosettaState?.result?.user?.isOverallChurned;
  const computeViewModel = (plan: Plan) => {
    const { pricing, resource } = plan;

    const computeMonthlyAndAnnualPricing = () =>
      plan.billingCycle === "1m"
        ? {
            annual: {
              intro: pricing.trialPrice === undefined ? undefined : pricing.trialPrice * 12,
              rack: pricing.rackPrice * 12,
            },
            isDisabled: pricing.isDisabled,
            monthly: {
              intro: pricing.trialPrice,
              rack: pricing.rackPrice,
            },
          }
        : {
            annual: {
              intro: pricing.trialPrice,
              rack: pricing.rackPrice,
            },
            isDisabled: pricing.isDisabled,
            monthly: {
              intro:
                pricing.trialPrice === undefined ? undefined : Math.round(pricing.trialPrice / 12),
              rack: Math.round(pricing.rackPrice / 12),
            },
          };
    const pricingViewModel = computeMonthlyAndAnnualPricing();
    const computePriceDescription = () =>
      `Total HKD ${
        pricingViewModel.annual.intro ?? pricingViewModel.annual.rack
      } for the first year`;
    const backgroundColor = isSelected ? "#f8ffac" : "";

    switch (resource) {
      case "postiesEPaperAndDigital":
        return {
          backgroundColor,
          balloonOption: {
            icon: <IconBlueBalloon />,
            position: "top-left" as const,
          },
          description: "Enjoy our digital platform and e-version of the printed copy",
          iconBackground: "#0f75bf",
          mascot: IconMascotPippa,
          priceDescription: computePriceDescription(),
          pricing: computeMonthlyAndAnnualPricing(),
          title: "ePaper + Digital",
        };
      case "postiesPrintAndDigital":
        return {
          backgroundColor,
          balloonOption: {
            icon: <IconRedBalloon />,
            position: "top-right" as const,
          },
          description:
            "Enjoy our digital platform with physical printed copies delivered to you, free of charge",
          iconBackground: "#ff9cb3",
          mascot: IconMascotPeter,
          priceDescription: computePriceDescription(),
          pricing: computeMonthlyAndAnnualPricing(),
          title: "Print + Digital",
        };
    }
  };

  const viewModel = computeViewModel(plan);

  const renderContent = () => (
    <Content onClick={() => handleSelected?.()}>
      <Icon backgroundColor={viewModel.iconBackground}>
        <Mascot src={viewModel.mascot.src} />
        <BalloonContainer $isVisible={withBalloon} $position={viewModel.balloonOption.position}>
          {viewModel.balloonOption.icon}
        </BalloonContainer>
      </Icon>
      <Title>{viewModel.title}</Title>
      <PriceContainer>
        <Currency>HKD</Currency>
        {!isOverallChurned && viewModel.pricing.monthly.intro && (
          <IntroPrice>{viewModel.pricing.monthly.intro}</IntroPrice>
        )}
        <RackPrice>{viewModel.pricing.monthly.rack}</RackPrice>
        <BillingCycleLabel>/month</BillingCycleLabel>
      </PriceContainer>
      <PriceDescription>{viewModel.priceDescription}</PriceDescription>
      <Description>{viewModel.description}</Description>
    </Content>
  );

  return (
    <Container
      $isDisabled={plan.pricing.isDisabled}
      backgroundColor={viewModel.backgroundColor}
      borderRadius="20px"
      className={className}
      offset={6}
    >
      {withLink ? (
        <BaseLink
          anchorProps={{ tabIndex: plan.pricing.isDisabled ? -1 : 0 }}
          pathname={withLink.url}
          target="_blank"
        >
          {renderContent()}
        </BaseLink>
      ) : (
        renderContent()
      )}
    </Container>
  );
};

PlanOption.displayName = "PlanOption";
