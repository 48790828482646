import { type RosettaSDK } from "@product/rosetta-sdk";
import { useAsync } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { PostiesPaywallImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { useCurrentArticleIds } from "scmp-app/lib/current-item/hooks";
import { rosettaAtom } from "scmp-app/lib/rosetta";

import type { BillingCycle, Plan } from "./types";

export const usePostiesData = () => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const [{ result }, { execute }] = useAsync(async (sdk: RosettaSDK) => {
    const { pricing, terms } = await sdk.getPostiesData();
    return {
      pricing,
      terms,
    };
  }, null);

  useEffect(() => {
    const sdk = asyncRosettaState?.result?.instance;
    if (!sdk) return;
    void execute(sdk);
  }, [asyncRosettaState?.result?.instance, execute]);

  if (result === null) {
    return {
      pricing: null,
      terms: [],
    };
  }
  return result;
};

export const useTracking = () => {
  const { currentArticleId } = useCurrentArticleIds();

  const getPaywallImpressionEvent: (
    termsOffered: string,
  ) => PostiesPaywallImpressionEvent = termsOffered => ({
    action: "imp",
    category: "subs",
    customized_parameters: {
      article_id: currentArticleId,
      call_to_action: "subscribe",
      paywall_status: "hard",
      terms_offered: termsOffered,
      trigger_point: "posties paywall",
    },
    subcategory: "entry_point",
  });

  const trackBillingCycleToggle = useCallback(
    (cycle: BillingCycle) => {
      const cycleLabel = cycle === "1y" ? "Yearly" : "Monthly";
      sendGA4Tracking({
        action: "click",
        category: "subs",
        customized_parameters: {
          article_id: currentArticleId,
          call_to_action: `toggle ${cycleLabel.toLowerCase()} plan`,
          paywall_status: "hard",
          trigger_point: "posties paywall",
        },
        subcategory: "entry_point",
      });
    },
    [currentArticleId],
  );

  const trackPlanSelected = useCallback(
    ({ plan, termsOffered }: { plan: Plan; termsOffered: string }) => {
      // TODO(SCMPWEB-4915): [GA4 Tracking] Replace the event parameter "plan_type" for the with "plan_id"
      sendGA4Tracking({
        action: "click",
        category: "subs",
        customized_parameters: {
          article_id: currentArticleId,
          call_to_action: "choose plan",
          paywall_status: "hard",
          term_offer_selected: plan.termId,
          terms_offered: termsOffered,
          trigger_point: "posties paywall",
        },
        subcategory: "entry_point",
      });
    },
    [currentArticleId],
  );

  const trackCompareBenefitClick = useCallback(() => {
    sendGA4Tracking({
      action: "click",
      category: "subs",
      customized_parameters: {
        article_id: currentArticleId,
        call_to_action: "compare benefits",
        paywall_status: "hard",
        trigger_point: "posties paywall",
      },
      subcategory: "entry_point",
    });
  }, [currentArticleId]);

  const trackRedeemClick = useCallback(() => {
    sendGA4Tracking({
      action: "click",
      category: "subs",
      customized_parameters: {
        article_id: currentArticleId,
        call_to_action: "redeem",
        paywall_status: "hard",
        trigger_point: "posties paywall",
      },
      subcategory: "entry_point",
    });
  }, [currentArticleId]);

  return {
    getPaywallImpressionEvent,
    trackBillingCycleToggle,
    trackCompareBenefitClick,
    trackPlanSelected,
    trackRedeemClick,
  };
};
