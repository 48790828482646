import { Term } from "@product/rosetta-sdk";
import { useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { useMemo, useState } from "react";

import { config } from "shared/data";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { PostiesPaywallImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";

import { usePostiesData, useTracking } from "./hooks";
import type { Props as PlanOptionProps } from "./plan-option";
import { Container, EPaperPlan, Footer, PlanContainer, PrintPlan, Title, Toggle } from "./styles";
import type { BillingCycle, Plan, Resource, Variant } from "./types";

type Props = {
  className?: string;
  variant: Variant;
};
export const SubscriptionWidget: FunctionComponent<Props> = ({ className, variant }) => {
  const { getPaywallImpressionEvent, trackBillingCycleToggle, trackPlanSelected } = useTracking();
  const [selectedBillingCycle, setSelectedBillingCycle] = useState<BillingCycle>("1y");
  const [selectedResource, setSelectedResource] = useState<Resource>("postiesPrintAndDigital");
  const { pricing, terms } = usePostiesData();
  const { plans, selectedPlan } = useMemo(() => {
    if (!pricing) return {};
    if (!terms || terms.length === 0) return {};

    const plans = terms.reduce(
      (plans, term) => {
        const { billingCycle, resource } = Term.decodeTermName(term.name);
        if (!resource || !billingCycle) return plans;
        if (billingCycle !== selectedBillingCycle) return plans;
        if (resource !== "postiesEPaperAndDigital" && resource !== "postiesPrintAndDigital")
          return plans;

        plans[resource] = {
          billingCycle,
          pricing: pricing[resource][billingCycle],
          resource,
          termId: term.term_id,
        };
        return plans;
      },
      {} as Record<Resource, Plan>,
    );

    return { plans, selectedPlan: plans[selectedResource] };
  }, [selectedBillingCycle, selectedResource, pricing, terms]);

  const termsOffered = plans
    ? Object.values(plans)
        .filter(plan => !plan.pricing.isDisabled)
        .map(plan => plan.termId)
        .join(",")
    : "";

  const { captureTrackImpressionEventTargetElement: trackImpressionTargetElement } =
    useTrackImpressionEventByIntersection<PostiesPaywallImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: getPaywallImpressionEvent.bind(null, termsOffered),
      options: {
        intersectionThreshold: [0.1],
        isSendGA4Tracking: true,
        shouldSendOnce: true,
      },
    });

  if (!plans) return null;

  const computeViewModelVariant = () => {
    switch (variant) {
      case "brochure":
        return {
          handleResourceSelected: setSelectedResource,
          hideSubscribeButton: false,
          withBalloon: true,
        };
      case "paywall":
        return {
          hideSubscribeButton: true,
          withBalloon: false,
          withLink: true,
        };
    }
  };
  const viewModelVariant = computeViewModelVariant();

  const computeSubscribeLink = (plan: Plan) => {
    // cspell: ignore postiesbundle
    const { billingCycle, resource } = plan;
    const subscribeHost = config.rosetta.subscribeHost;
    const resourceName = resource === "postiesEPaperAndDigital" ? "posties" : "postiesbundle";
    return `${subscribeHost}/posties?plan=${resourceName}${billingCycle}`;
  };
  const computePlanOptionProps = (plan: Plan) => {
    const props: PlanOptionProps = {
      handleSelected() {
        trackPlanSelected({ plan, termsOffered });
        viewModelVariant.handleResourceSelected?.(plan.resource);
      },
      isSelected: plan === selectedPlan,
      plan,
      withBalloon: viewModelVariant.withBalloon,
      withLink: viewModelVariant.withLink
        ? {
            url: computeSubscribeLink(plan),
          }
        : undefined,
    };
    return props;
  };

  return (
    <Container className={className}>
      <Title>Pick a plan that suits you</Title>
      <Toggle
        billingCycle={selectedBillingCycle}
        handleChange={value => {
          trackBillingCycleToggle(value);
          setSelectedBillingCycle(value);
          if (value === "1m") setSelectedResource("postiesEPaperAndDigital");
        }}
      />
      <PlanContainer ref={trackImpressionTargetElement}>
        <EPaperPlan {...computePlanOptionProps(plans.postiesEPaperAndDigital)} />
        <PrintPlan {...computePlanOptionProps(plans.postiesPrintAndDigital)} />
      </PlanContainer>
      <Footer
        disableSubscribeButton={selectedPlan.pricing.isDisabled}
        hideSubscribeButton={viewModelVariant.hideSubscribeButton}
        subscribeUrl={computeSubscribeLink(selectedPlan)}
        variant={variant}
      />
    </Container>
  );
};

SubscriptionWidget.displayName = "SubscriptionWidget";
