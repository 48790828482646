import { NoSsr } from "@mui/base";
import { useAtomValue } from "jotai";
import type { FunctionComponent } from "react";

import { BaseLink } from "shared/components/common/base-link";
import { config } from "shared/data";
import { accountAtom } from "shared/lib/account";

import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import { useTracking } from "scmp-app/components/posties/subscription-widget/hooks";
import type { Variant } from "scmp-app/components/posties/subscription-widget/types";

import { Benefit, Container, IconArrow, Link, LogIn, LogInLink, Redeem, Subscribe } from "./styles";

type Props = {
  className?: string;
  disableSubscribeButton?: boolean;
  hideSubscribeButton?: boolean;
  subscribeUrl: string;
  variant: Variant;
};

export const Footer: FunctionComponent<Props> = ({
  className,
  disableSubscribeButton,
  hideSubscribeButton,
  subscribeUrl,
}) => {
  const subscribeHost = config.rosetta.subscribeHost;
  const { isLoggedIn } = useAtomValue(accountAtom);
  const { openLoginDialog } = useLoginDialogStateHelper();
  const { trackCompareBenefitClick, trackRedeemClick } = useTracking();

  return (
    <Container className={className}>
      <Benefit
        onClick={trackCompareBenefitClick}
        pathname={`${subscribeHost}/posties`}
        target="_blank"
      >
        Compare benefits
        <IconArrow />
      </Benefit>
      {!hideSubscribeButton && (
        <Subscribe $isDisabled={disableSubscribeButton}>
          <BaseLink
            anchorProps={{ tabIndex: disableSubscribeButton ? -1 : 0 }}
            pathname={subscribeUrl}
            target="_blank"
          >
            Subscribe
          </BaseLink>
        </Subscribe>
      )}
      <NoSsr>
        {!isLoggedIn && (
          <LogIn>
            Already a subscriber?{" "}
            <LogInLink
              onClick={() =>
                openLoginDialog({
                  application: "posties",
                  destination: window.location.href,
                  ga4CustomParameter: {
                    trigger_point: "posties",
                  },
                })
              }
            >
              Log In
            </LogInLink>
          </LogIn>
        )}
      </NoSsr>
      <Redeem>
        Or you can{" "}
        <Link
          onClick={trackRedeemClick}
          pathname={`${subscribeHost}/posties/redeem`}
          target="_blank"
        >
          Redeem
        </Link>{" "}
        here
      </Redeem>
    </Container>
  );
};
